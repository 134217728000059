// import styled from "styled-components"

const GoogleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none">
        <path
            fill="#4285F4"
            fillRule="evenodd"
            d="M22.65 14.699c0-.62-.056-1.217-.16-1.79h-8.24v3.385h4.71a4.025 4.025 0 0 1-1.747 2.64v2.196h2.828c1.654-1.523 2.609-3.766 2.609-6.431Z"
            clipRule="evenodd"
        />
        <path
            fill="#34A853"
            fillRule="evenodd"
            d="M14.25 23.25c2.363 0 4.343-.783 5.79-2.12l-2.827-2.195c-.784.525-1.786.835-2.963.835-2.279 0-4.208-1.54-4.896-3.607H6.431v2.267a8.747 8.747 0 0 0 7.819 4.82Z"
            clipRule="evenodd"
        />
        <path
            fill="#FBBC05"
            fillRule="evenodd"
            d="M9.354 16.163A5.26 5.26 0 0 1 9.08 14.5c0-.577.099-1.137.274-1.662V10.57H6.431A8.746 8.746 0 0 0 5.5 14.5c0 1.411.338 2.747.93 3.929l2.924-2.267Z"
            clipRule="evenodd"
        />
        <path
            fill="#EA4335"
            fillRule="evenodd"
            d="M14.25 9.23c1.285 0 2.438.442 3.345 1.309l2.51-2.51C18.589 6.617 16.608 5.75 14.25 5.75c-3.42 0-6.38 1.96-7.82 4.82l2.924 2.268c.688-2.069 2.617-3.608 4.896-3.608Z"
            clipRule="evenodd"
        />
    </svg>
)

// const FacebookIcon = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none">
//         <path
//             fill="#1877F2"
//             fillRule="evenodd"
//             d="M3.75 14.5c0 5.192 3.792 9.508 8.75 10.383l.059-.046-.059-.012v-7.408H9.875V14.5H12.5v-2.333c0-2.625 1.692-4.084 4.083-4.084.759 0 1.575.117 2.334.234V11h-1.342C16.292 11 16 11.642 16 12.458V14.5h2.8l-.467 2.917H16v7.408l-.059.012.059.046c4.958-.875 8.75-5.191 8.75-10.383 0-5.775-4.725-10.5-10.5-10.5S3.75 8.725 3.75 14.5Z"
//             clipRule="evenodd"
//         />
//     </svg>
// )

// const StyledLink = styled.a`
//     color: #1C3054;
//     font-size: 18px;
//     line-height: 18px;
//     min-height: 50px;
//     width: calc((100% - 10px) / 2);
//     text-decoration: none;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: white;
//     border-radius: 6px;
//     border: 1px solid #1C3054;
//     padding: 4px;
//     @media (max-width: 808.98px) {
//         width: 100%;
//     }
// `

// const StyledDiv = styled.div`
//     display: flex;
//     flex-wrap: ${(props) => (props.$wrap ? "wrap" : "nowrap")};
//     justify-content: space-between;
//     gap: 10px;
// `

// const StyledHR = styled.hr`
//     display: block;
//     height: 1px;
//     background-color: #1C3054;
//     border: none;
//     flex-grow: 1;
// `

export default function SocialLinks(props) {
    const StyledLink = {
        color: "#1C3054",
        fontSize: "18px",
        lineHeight: "18px",
        minHeight: "50px",
        width: "calc((100% - 10px))",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: "6px",
        border: "1px solid #1C3054",
        padding: "4px",
        // Media query styles are not supported in inline styles
    }

    const StyledDiv = {
        display: "flex",
        flexWrap: "wrap", // This value should be dynamically set in your component
        justifyContent: "space-between",
        gap: "10px",
    }

    const StyledHR = {
        display: "block",
        height: "1px",
        backgroundColor: "#1C3054",
        border: "none",
        flexGrow: "1",
    }

    const handleClick = (e) => {
        e.preventDefault()
        const rootUrl = window.location.host.includes("divorce.com")
            ? "https://divorce.com"
            : "https://staging.completecase.com:8190"
        window.location.href = `${rootUrl}${e.target.href}`
    }

    return (
        <>
            <div style={StyledDiv}>
                <hr style={StyledHR} />
                <div>Or sign up with</div>
                <hr style={StyledHR} />
            </div>
            <div style={StyledDiv}>
                <a
                    style={StyledLink}
                    href={`https://divorce.com/social-auth/google/login/`}
                    data-provider="google"
                    onClick={handleClick}
                    {...props}
                >
                    <GoogleIcon />
                    Google
                </a>
            </div>
        </>
    )
}
